<template>
    <master-panel>
        <template v-slot:body>
            <v-row>
                <v-col cols="12">
                    <title-container :title="clientName"/>
                </v-col>
                <v-col cols="12">
                    <my-exams :exams="exams"/>
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-row>
                <v-col cols="6" align="right" class="pa-1 pl-3">
                    <v-btn block color="error" class="normal-btn" small depressed @click="onReject">
                        <v-icon left v-text="'mdi-close'"/>
                        Rechazar
                    </v-btn>
                </v-col>
                <v-col cols="6" class="pa-1 pr-3">
                    <v-btn block color="success" class="normal-btn" small depressed @click="onApprove">
                        Aprobar
                        <v-icon right v-text="'$check'"/>
                    </v-btn>
                </v-col>
                <v-col cols="6" align="right" class="pa-1 pl-3">
                    <v-btn block color="primary" class="normal-btn" small depressed @click="goBack">
                        Volver
                    </v-btn>
                </v-col>
                <v-col cols="6" class="pa-1 pr-3">
                    <v-btn block color="primary" class="normal-btn" small depressed @click="goHome">
                        Dashboard
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </master-panel>
</template>

<script>
import MyExams from "@/components/client/MyExams";
import MasterPanel from "@/components/layouts/MasterPanel";
import httpService from "@/services/httpService";
import TitleContainer from "@/components/elements/TitleContainer";
import {DOCUMENTS, STATUS} from "@/utils/Constants";

export default {
    name: "ExamsDetails",
    components: {TitleContainer, MasterPanel, MyExams},
    data() {
        return {
            loading: false,
            client: null,
            exams: [
                {
                    name: 'Endoscopia',
                    icon: '$endoscopy',
                    color: 'primary',
                    dark: true,
                    url: '',
                    document_type_id: DOCUMENTS.ENDOSCOPY
                },
                {
                    name: 'Laboratorio',
                    icon: '$laboratory',
                    color: 'secondary',
                    dark: true,
                    url: '',
                    document_type_id: DOCUMENTS.LABORATORY
                },
                {
                    name: 'Cardiología',
                    icon: '$cardiological',
                    color: 'ternary',
                    dark: false,
                    url: '',
                    document_type_id: DOCUMENTS.CARDIOLOGICAL
                },
                {
                    name: 'Ecografía',
                    icon: '$sonography',
                    color: 'highlight',
                    dark: true,
                    url: '',
                    document_type_id: DOCUMENTS.SONOGRAPHY
                },
                {
                    name: 'Pase Nutricional',
                    icon: 'mdi-file-document-outline',
                    color: 'secondary',
                    dark: true,
                    url: '',
                    document_type_id: DOCUMENTS.NUTRITIONAL_PASS
                },
                {
                    name: 'Pase Psicológico',
                    icon: 'mdi-file-document-outline',
                    color: 'secondary',
                    dark: true,
                    url: '',
                    document_type_id: DOCUMENTS.NUTRITIONAL_PASS
                },
            ]
        }
    },
    computed: {
        clientId(vm = this) {
            return vm.$route.params.id || null
        },
        clientName(vm = this) {
            return vm.client?.name || ''
        }
    },
    async mounted() {
        const vm = this
        await vm.loadExams()
    },
    methods: {
        onReject() {
            const vm = this
            vm.goTo({name: 'doctor.exams.details.message', params: {status: STATUS.REJECT}})
        },
        onApprove() {
            const vm = this
            vm.goTo({name: 'doctor.exams.details.message', params: {status: STATUS.APPROVE}})
        },
        async loadExams() {
            const vm = this
            vm.loading = true

            let response = await httpService.get(`client/${vm.clientId}/exams`)
            // console.log('response', response)
            vm.client = response.data.client
            response.data.exams.forEach(exam => {
                let index = vm.exams.findIndex(e => e.document_type_id === exam.document_type_id)
                if (index >= 0) vm.exams[index].url = vm.getFilePath(exam.path, exam.name)
            })

            vm.loading = false
        },
    }
}
</script>

<style scoped>

</style>