<template>
    <v-row>
        <v-col
            cols="6" v-for="(exam,i) in exams" :key="i"
            :class="['text-center', {'no-url': !exam.url}]"
            v-ripple="exam.url" @click="onCLickExam(exam.url)"
        >
            <v-sheet :color="exam.color" class="exam-container" :dark="exam.dark">
                <v-icon size="75" v-text="exam.icon"/>
            </v-sheet>
            <div class="exam-text">
                {{ exam.name }}
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "MyExams",
    props: {
        exams: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        onCLickExam(url) {
            const vm = this
            if (!url) return;
            window.open(url, '_blank')
        }
    }
}
</script>

<style scoped>

.exam-container {
    display: inline-block;
    padding: 12px;
    border-radius: 12px;
}

.exam-text {
    color: #1a1a1a;
    margin-top: 4px;
    font-size: 14px;
}

.no-url {
    opacity: 0.4;
}

</style>